import Vue from 'vue';
import App from './App.vue';
import makeStore from './store/store';

Vue.config.productionTip = false;

export function main(opts) {
  const store = makeStore(opts);
  const el = typeof opts.el !== 'undefined' ? opts.el : '#app';
  const initSelection = {
    year: null,
    make: null,
    model: null,
    drivebody: null,
    submodel: null,
    ...opts.initSelection
  };

  new Vue({
    store,
    render: h => h(App)
  }).$mount(el);

  preloadSelection(store, initSelection).then();
}

async function preloadSelection(store, selection) {
  if (selection.year) {
    await store.dispatch('setYear', selection.year);
  }
  if (selection.make) {
    await store.dispatch('setMake', selection.make);
  }
  if (selection.model) {
    await store.dispatch('setModel', selection.model);
  }
  if (selection.drivebody) {
    await store.dispatch('setDriveBody', selection.drivebody);
  }
  if (selection.submodel) {
    await store.dispatch('setSubModel', selection.submodel);
  }
}

if (process.env.NODE_ENV === 'development') {
  main({
    // eslint-disable-next-line no-console
    selectionCallback: selection => selection,
    configuratorId: 13768,
    // 2016 Ford F-150
    initSelection: {
      year: 2016,
      make: 54,
      model: 666,
      drivebody: '4WD:2 DR STANDARD CAB PICKUP, 6 FT BED',
      submodel: '649:150304'
    }
  });
}

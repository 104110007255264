import axios from 'axios';
/*
const fetcher = axios.create({
  baseURL: 'http://www.iconfigurators.com/json2',
  params: {
    returnType: 'json',
    id: 13768
  }
});
*/

let fetcher = null;

export function initFetcher(configuratorId) {
  fetcher = axios.create({
    baseURL: '//www.iconfigurators.com/json2',
    params: {
      returnType: 'json',
      bypass: true,
      id: configuratorId
    }
  });
}

function iconFetch(opts) {
  return fetcher.get('/', opts).then(result => {
    return result;
  });
}

export const Vehicles = {
  getYears() {
    return iconFetch({
      params: {
        callback: 'yearObj'
      }
    });
  },
  getMakes(year) {
    return iconFetch({
      params: {
        callback: 'makeObj',
        year: year
      }
    });
  },
  getModels(year, make) {
    return iconFetch({
      params: {
        callback: 'modelObj',
        year: year,
        make: make
      }
    });
  },
  getDriveBody(year, make, model) {
    return iconFetch({
      params: {
        callback: 'bodyObj',
        year: year,
        make: make,
        model: model
      }
    });
  },
  getSubModels(year, make, model, drivebody) {
    return iconFetch({
      params: {
        callback: 'subModelFGObj',
        year: year,
        make: make,
        model: model,
        drivebody: drivebody
      }
    });
  },
  getSizes(year, make, model, drivebody, submodel) {
    return iconFetch({
      params: {
        callback: 'sizeFGObj',
        year: year,
        make: make,
        model: model,
        drivebody: drivebody,
        submodel: submodel
      }
    });
  }
};
